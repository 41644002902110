import Vue from 'vue'
// import axios from 'axios'
import ViewUI from 'view-design'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App'
import store from './store'
import router from './router'
import 'view-design/dist/styles/iview.css'
import './permission'
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import mixin from './mixins/globalMixins';
import permission from './directive/Permission';
import SecondsTransfer from './directive/SecondsTransfer';
import PermissionButton from "@/components/PermissionButton.vue";
import VueClipBoard from 'vue-clipboard2';
 
Vue.use(VueClipBoard);
// // 修改 el-dialog 默认点击遮照为不关闭\全局设置点击遮罩层不关闭 Dialog
// Element.Dialog.props.closeOnClickModal.default = false
//Element-UI抽屉Drawer，阻止点击遮罩层关闭
ElementUI.Drawer.props.wrapperClosable.default = false
// import BaiduMap from 'vue-baidu-map';
Vue.use(permission)
Vue.component('permission-button', PermissionButton)
// 注册名为 change 的指令
Vue.directive("seconds", SecondsTransfer);
Vue.mixin(mixin)
Vue.config.productionTip = false
Vue.use(ViewUI)
Vue.use(Treeselect)
Vue.use(ElementUI)

// Vue.prototype.$axios = axios
// Vue.use(BaiduMap, {
//     ak: "dSeM0HPCeKXRjAKzFYXSsgd765LrdAbm",
// })
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})