<template>
  <el-button
    :type="type"
    :size="size"
    @click.stop="emitClick"
    v-if="!confirm"
    class="permissionButton"
    :disabled="disabled"
  >
    <slot></slot>
  </el-button>
  <el-popconfirm
    v-else
    :title="confirmText"
    @confirm="emitClick"
    class="permissionButton"
  >
    <el-button :type="type" :size="size" slot="reference" :disabled="disabled">
      <slot></slot>
    </el-button>
  </el-popconfirm>
</template>

<script>
export default {
  name: "PermissionButton",
  props: {
    type: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "small",
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: "是否确认删除此选项？",
    },
  },
  computed: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.permissionButton {
  margin: 0 5px;
}
</style>