<template>
    <div id="app">     
        <router-view ></router-view>
        <div class="global-loading" v-show="isShowLoading">
            <Spin size="large"></Spin>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "App",
    watch: {
        $route: function (to, from) {
            // to:跳转到的导航地址
            this.currentRouter = to.path;
            // console.log(" this.currentRouter: ", this.currentRouter);
        },
    },
    beforeMount() {
        this.currentRouter = this.$route.path;
    },
    mounted() {
        // window.reload = false
    },
    data() {
        return {
            keepAliveData: ["manage"],
            currentRouter: "/",
        };
    },
    computed: {
        ...mapState(["isShowLoading"]),
    },
    methods: {
        // handleBeforeUnload() {
        //   window.reload = true;
        // },
    },
};
</script>

<style lang="scss">
@import "@/style/common.scss";

body {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
li,
ul,
p,
div,
body,
html,
table {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html,
body {
    height: 100%;
    overflow: hidden;
}
li {
    list-style: none;
}
#app {
    height: 100%;
}
/* loading */
.global-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}
</style>
