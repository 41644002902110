// js实现动态变化的数字
const directive = {
    inserted(el, binding) {
        // 最终要显示的数字
        // let finalNum = el.innerText;
        let initNum = Number(binding.value.count);
        // console.log("finalNum: ", finalNum);
        // 动画间隔
        let rate = 1000;
        // 步长,数据增加间隔
        let step = 1;
        // 初始值
        // let count =Json.parse(JSON.stringify(finalNum));
        let timer = setInterval(() => {
            if (initNum < 1) {
                clearInterval(timer);
                timer = null;
            } else {
                initNum = binding.value.tag ? initNum + step : initNum - step;
            }
            el.innerText = minSecond(initNum);
        }, rate);
        // clearInterval(timer);
        // timer = null;
        // clearInterval(timer)
    },
};
function minSecond(n) {
    let second = Math.floor(n % 60);
    if (second < 10) {
        second = "0" + second;
    }
    let a = null;
    if (Math.floor(n / 60) < 10) {
        a = "0" + Math.floor(n / 60) + "分" + second + "秒";
    } else {
        a = Math.floor(n / 60) + "分" + second + "秒";
    }
    return a;
}
export default directive;
