import { LoadingBar } from 'view-design'
import router from './router'
import store from './store'
import createRoutes from '@/utils/createRoutes'
import { getDocumentTitle, resetTokenAndClearUser } from './utils'
import { fetchAccountMenuTreeList } from '@/api';
import { asyncRoutes } from '@/router'


// 是否有菜单数据
let hasMenus = false
router.beforeEach(async (to, from, next) => {
    // console.log('hasMenus: ', hasMenus);
    document.title = getDocumentTitle(to.meta.title)
    LoadingBar.start()
    if (localStorage.getItem('token')) {
        if (to.path === '/login') {
            next({ path: '/' })
        }
        // else if (hasMenus) {
        //     next()
        // }
        else {
            try {
                const asyncMenu = JSON.parse(sessionStorage.getItem('menuItems'))
                if (!hasMenus) {
                    asyncMenu.forEach(item => {
                        generateRoutes(item)
                    })
                    hasMenus = true
                    next({ ...to, replace: true })
                } else {
                    next()
                }

            } catch (error) {
                resetTokenAndClearUser()
                next(`/login?redirect=${to.path}`)
            }
        }
    } else {
        hasMenus = false
        if (to.path === '/login') {
            next()
        } else {
            next(`/login?redirect=${to.path}`)
            // next()
        }
    }
})

router.afterEach(() => {
    LoadingBar.finish()
})

function generateRoutes(item) {
    if (item.vue_route) {
        // router.matcher = new Router().matcher 
        router.addRoute('index', asyncRoutes[item.vue_route])
    }
    if (item.children) {
        item.children.forEach(e => {
            generateRoutes(e)
        })
    }
}
