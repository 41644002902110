import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const commonRoutes = [
    {
        path: "/login",
        name: "login",
        meta: { title: "登录" },
        component: () => import("../components/Login.vue"),
    },
    {
        path: "/index", // 点击侧边栏跳到一个单独的路由页面，需要定义，层级和其他顶级路由一样
        name: "index",
        meta: { title: "主页" },
        // component: () => import('../views/HomeIndex.vue'),
        component: () => import("../components/IndexNew.vue"),
    },
    {
        path: "/404",
        name: "404",
        meta: { title: "404" },
        component: () => import("../components/404.vue"),
    },
    // {
    //     path: "/liveEdit",
    //     name: "liveEdit",
    //     meta: { title: "直播管理", keepAlive: false },
    //     component: () => import("../views/liveBroadcast/LiveEdit.vue"),
    // },
    { path: "/", redirect: "/index" },
];

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    // home: {
    //     path: "/home",
    //     // name: 'home',
    //     meta: { title: "主页" },
    //     component: () => import("../views/Home.vue"),
    //     // component: () => import('../components/Index.vue'),
    // },

    // 工作台
    workStand: {
        path: "/workStand",
        name: "workStand",
        meta: { title: "工作台" },
        component: () => import("../views/workStand/index.vue"),
    },
    trade: {
        path: "/trade",
        name: "trade",
        meta: { title: "交易" },
        component: () => import("../views/trade/index.vue"),
    },
    menuIndex: {
        path: "/menuIndex",
        name: "menuIndex",
        meta: { title: "菜单管理" },
        component: () => import("../views/menuManagement/index.vue"),
    },
    roleIndex: {
        path: "/roleIndex",
        name: "roleIndex",
        meta: { title: "角色管理" },
        component: () => import("../views/roleManagement/index.vue"),
    },
    departmentIndex: {
        path: "/departmentIndex",
        name: "departmentIndex",
        meta: { title: "部门管理" },
        component: () => import("../views/departmentManagement/index.vue"),
    },
    erpProduct: {
        path: "/erpProduct",
        name: "erpProduct",
        meta: { title: "ERP商品库" },
        component: () => import("../views/productLibrary/ERPProduct.vue"),
    },
    platformProduct: {
        path: "/platformProduct",
        name: "platformProduct",
        meta: { title: "平台商品库", keepAlive: true },
        component: () => import("../views/productLibrary/PlatformProduct.vue"),
    },
    productEdit: {
        path: "/productEdit",
        name: "productEdit",
        meta: { title: "商品编辑" },
        component: () => import("../views/productLibrary/components/ProductEdit.vue"),
    },
    orderIndex: {
        path: "/orderIndex",
        name: "orderIndex",
        meta: { title: "订单管理", keepAlive: true },
        component: () => import("../views/orderManagement/index.vue"),
    },
    shopIndex: {
        path: "/shopIndex",
        name: "shopIndex",
        meta: { title: "门店管理", keepAlive: true },
        component: () => import("../views/shopManagement/index.vue"),
    },
    shopAdd: {
        path: "/shopAdd",
        name: "shopAdd",
        meta: { title: "新增门店" },
        component: () => import("../views/shopManagement/components/BasicInfo.vue"),
    },
    shopEdit: {
        path: "/shopEdit",
        name: "shopEdit",
        meta: { title: "门店详情", keepAlive: false },
        component: () => import("../views/shopManagement/components/ShopEdit.vue"),
    },
    memberIndex: {
        path: "/memberIndex",
        name: "memberIndex",
        meta: { title: "会员管理", keepAlive: true },
        component: () => import("../views/memberManagement/index.vue"),
    },
    memberDetail: {
        path: "/memberDetail",
        name: "memberDetail",
        meta: { title: "会员详情" },
        component: () => import("../views/memberManagement/MemberDetail.vue"),
    },
    memberCurrencyReport: {
        path: "/memberCurrencyReport",
        name: "memberCurrencyReport",
        meta: { title: "会员货币报表" },
        component: () => import("../views/memberCurrencyReport/index.vue"),
    },
    orderDetail: {
        path: "/orderDetail",
        name: "orderDetail",
        meta: { title: "订单详情" },
        component: () => import("../views/orderManagement/components/OrderDetail.vue"),
    },
    beginPage: {
        path: "/beginPage",
        name: "beginPage",
        meta: { title: "启动页" },
        component: () => import("../views/marketing/AppAdvertisement/BeginPage.vue"),
    },
    popupNotification: {
        path: "/popupNotification",
        name: "popupNotification",
        meta: { title: "弹窗页" },
        component: () => import("../views/marketing/AppAdvertisement/BeginPage.vue"),
    },
    couponIndex: {
        path: "/couponIndex",
        name: "couponIndex",
        meta: { title: "优惠券" },
        component: () => import("../views/marketing/CouponIndex.vue"),
    },
    fullDelivery: {
        path: "/fullDelivery",
        name: "fullDelivery",
        meta: { title: "满就送" },
        component: () => import("../views/marketing/FullDeliveryIndex.vue"),
    },
    singleActivity: {
        path: "/singleActivity",
        name: "singleActivity",
        meta: { title: "单品活动" },
        component: () => import("../views/marketing/ActivityIndex.vue"),
    },
    exchangePurchaseActivity: {
        path: "/exchangePurchaseActivity",
        name: "exchangePurchaseActivity",
        meta: { title: "换购活动" },
        component: () => import("../views/marketing/ExchangePurchaseActivity.vue"),
    },
    memberActivity: {
        path: "/memberActivity",
        name: "memberActivity",
        meta: { title: "会员活动" },
        component: () => import("../views/marketing/ActivityIndex.vue"),
    },
    refundIndex: {
        path: "/refundIndex",
        name: "refundIndex",
        meta: { title: "退货退款", keepAlive: true },
        component: () => import("../views/refundManegement/index.vue"),
    },
    productSku: {
        path: "/productSku",
        name: "productSku",
        meta: { title: "商品SKU" },
        component: () => import("../views/operation/ProductSku/ProductSku.vue"),
    },
    evaluateList: {
        path: "/evaluateList",
        name: "evaluateList",
        meta: { title: "评价管理" },
        component: () => import("../views/refundManegement/evaluateList.vue"),
    },
    homePageList: {
        path: "/homePageList",
        name: "homePageList",
        meta: { title: "首页列表" },
        component: () => import("../views/operation/PageSettings/HomePageList.vue"),
    },
    activityPageList: {
        path: "/activityPageList",
        name: "activityPageList",
        meta: { title: "活动页列表" },
        component: () => import("../views/operation/PageSettings/ActivityPageList.vue"),
    },
    homeSetting: {
        path: "/homeSetting",
        name: "homeSetting",
        meta: { title: "首页编辑" },
        component: () => import("../views/operation/PageSettings/HomeSetting.vue"),
    },
    componentList: {
        path: "/componentList",
        name: "componentList",
        meta: { title: "组件列表" },
        component: () => import("../views/operation/PageSettings/ComponentList.vue"),
    },
    infoIndex: {
        path: "/infoIndex",
        name: "infoIndex",
        meta: { title: "咨询管理" },
        component: () => import("../views/operation/InfoManagement/index.vue"),
    },
    feedback: {
        path: "/feedback",
        name: "feedback",
        meta: { title: "意见反馈" },
        component: () => import("../views/operation/InfoManagement/Feedback.vue"),
    },
    promotionIndex: {
        path: "/promotionIndex",
        name: "promotionIndex",
        meta: { title: "推广管理" },
        component: () => import("../views/memberPromotion/index.vue"),
    },
    detailsAdvertisement: {
        path: "/detailsAdvertisement",
        name: "detailsAdvertisement",
        meta: { title: "APP详情广告" },
        component: () => import("../views/detailsAdvertisement/index.vue"),
    },
    shopPromotionIndex: {
        path: "/shopPromotionIndex",
        name: "shopPromotionIndex",
        meta: { title: "门店推广" },
        component: () => import("../views/shopPromotion/index.vue"),
    },
    tagsIndex: {
        path: "/tagsIndex",
        name: "tagsIndex",
        meta: { title: "会员标签" },
        component: () => import("../views/memberTags/TagsIndex.vue"),
    },
    memberNotice: {
        path: "/memberNotice",
        name: "memberNotice",
        meta: { title: "会员公告管理" },
        component: () => import("../views/memberNotice/index.vue"),
    },
    balanceReset: {
        path: "/balanceReset",
        name: "balanceReset",
        meta: { title: "余额充值" },
        component: () => import("../views/balanceReset/index.vue"),
    },
    changeRecharge: {
        path: "/changeRecharge",
        name: "changeRecharge",
        meta: { title: "零钱充值" },
        component: () => import("../views/changeRecharge/index.vue"),
    },
    paramSettings: {
        path: "/paramSettings",
        name: "paramSettings",
        meta: { title: "参数设置" },
        component: () => import("../views/system/ParamSettings/ParamSettings.vue"),
    },
    orderReport: {
        path: "/orderReport",
        name: "orderReport",
        meta: { title: "订单报表" },
        component: () => import("../views/orderReport/index.vue"),
    },
    goodsAllDegrees: {
        path: "/goodsAllDegrees",
        name: "goodsAllDegrees",
        meta: { title: "商品360" },
        component: () => import("../views/goodsAllDegrees/index.vue"),
    },
    classAllDegrees: {
        path: "/classAllDegrees",
        name: "classAllDegrees",
        meta: { title: "分类360" },
        component: () => import("../views/classAllDegrees/index.vue"),
    },
    shopAllDegrees: {
        path: "/shopAllDegrees",
        name: "shopAllDegrees",
        meta: { title: "门店360" },
        component: () => import("../views/shopAllDegrees/index.vue"),
    },
    currencyIndex: {
        path: "/currencyIndex",
        name: "currencyIndex",
        meta: { title: "货币管理" },
        component: () => import("../views/currency/index.vue"),
    },
    integralRecharge: {
        path: "/integralRecharge",
        name: "integralRecharge",
        meta: { title: "积分充值" },
        component: () => import("../views/integralRecharge/index.vue"),
    },
    offlineOrders: {
        path: "/offlineOrders",
        name: "offlineOrders",
        meta: { title: "线下订单" },
        component: () => import("../views/offlineOrders/index.vue"),
    },
    pointsMall: {
        path: "/pointsMall",
        name: "pointsMall",
        meta: { title: "积分商城" },
        component: () => import("../views/pointsMall/index.vue"),
    },
    appVersion: {
        path: "/appVersion",
        name: "appVersion",
        meta: { title: "app版本管理" },
        component: () => import("../views/appVersion/index.vue"),
    },
    salesReport: {
        path: "/salesReport",
        name: "salesReport",
        meta: { title: "收银报表" },
        component: () => import("../views/salesReport/index.vue"),
    },
    salesReportNew: {
        path: "/salesReportNew",
        name: "salesReportNew",
        meta: { title: "新收银报表" },
        component: () => import("../views/salesReportNew/index.vue"),
    },
    paidMemberReport: {
        path: "/paidMemberReport",
        name: "paidMemberReport",
        meta: { title: "会员销售报表" },
        component: () => import("../views/paidMemberReport/index.vue"),
    },
    changeSalesReport: {
        path: "/changeSalesReport",
        name: "changeSalesReport",
        meta: { title: "零钱销售报表" },
        component: () => import("../views/changeSalesReport/index.vue"),
    },
    pointsAndBalance: {
        path: "/pointsAndBalance",
        name: "pointsAndBalance",
        meta: { title: "移动积分会员余额" },
        component: () => import("../views/pointsAndBalance/index.vue"),
    },
    tripartitePayment: {
        path: "/tripartitePayment",
        name: "tripartitePayment",
        meta: { title: "三方支付统计" },
        component: () => import("../views/tripartitePayment/index.vue"),
    },
    balanceDetail: {
        path: "/balanceDetail",
        name: "balanceDetail",
        meta: { title: "会员余额明细" },
        component: () => import("../views/balanceDetail/index.vue"),
    },
    totalBalance: {
        path: "/totalBalance",
        name: "totalBalance",
        meta: { title: "会员余额汇总" },
        component: () => import("../views/totalBalance/index.vue"),
    },
    totalPoints: {
        path: "/totalPoints",
        name: "totalPoints",
        meta: { title: "移动积分汇总" },
        component: () => import("../views/totalPoints/index.vue"),
    },
    memberReport: {
        path: "/memberReport",
        name: "memberReport",
        meta: { title: "会员报表" },
        component: () => import("../views/memberReport/index.vue"),
    },
    changeDetail: {
        path: "/changeDetail",
        name: "changeDetail",
        meta: { title: "会员零钱明细" },
        component: () => import("../views/changeDetail/index.vue"),
    },
    pointsDetail: {
        path: "/pointsDetail",
        name: "pointsDetail",
        meta: { title: "会员积分明细" },
        component: () => import("../views/pointsDetail/index.vue"),
    },
    promoteReport: {
        path: "/promoteReport",
        name: "promoteReport",
        meta: { title: "推广报表" },
        component: () => import("../views/promoteReport/index.vue"),
    },
    rfmReport: {
        path: "/rfmReport",
        name: "rfmReport",
        meta: { title: "RFM表" },
        component: () => import("../views/rfmReport/index.vue"),
    },
    hisenseFlow: {
        path: "/hisenseFlow",
        name: "hisenseFlow",
        meta: { title: "海信流水" },
        component: () => import("../views/hisenseFlow/index.vue"),
    },
    hotWords: {
        path: "/hotWords",
        name: "hotWords",
        meta: { title: "热词" },
        component: () => import("../views/hotWords/index.vue"),
    },
    preSaleOrderIndex: {
        path: "/preSaleOrderIndex",
        name: "preSaleOrderIndex",
        meta: { title: "预售订单管理", keepAlive: true },
        component: () => import("../views/preSaleOrder/index.vue"),
    },
    preSaleGoods: {
        path: "/preSaleGoods",
        name: "preSaleGoods",
        meta: { title: "预售商品库", keepAlive: false },
        component: () => import("../views/preSaleGoods/index.vue"),
    },
    preSaleGoodsEdit: {
        path: "/preSaleGoodsEdit",
        name: "preSaleGoodsEdit",
        meta: { title: "预售商品详情", keepAlive: false },
        component: () => import("../views/preSaleGoods/components/ProductEdit.vue"),
    },
    preSaleShop: {
        path: "/preSaleShop",
        name: "preSaleShop",
        meta: { title: "预售门店", keepAlive: false },
        component: () => import("../views/preSaleShop/index.vue"),
    },
    preSaleShopEdit: {
        path: "/preSaleShopEdit",
        name: "preSaleShopEdit",
        meta: { title: "预售门店编辑", keepAlive: false },
        component: () => import("../views/preSaleShop/components/BasicInfo.vue"),
    },
    preSaleActivity: {
        path: "/preSaleActivity",
        name: "preSaleActivity",
        meta: { title: "预售活动", keepAlive: true },
        component: () => import("../views/preSaleActivity/index.vue"),
    },
    preSaleOrderDetail: {
        path: "/preSaleOrderDetail",
        name: "preSaleOrderDetail",
        meta: { title: "预售订单详情", keepAlive: false },
        component: () => import("../views/preSaleOrder/components/OrderDetail.vue"),
    },
    releaseIndex: {
        path: "/releaseIndex",
        name: "releaseIndex",
        meta: { title: "发布管理", keepAlive: false },
        component: () => import("../views/releaseManagement/index.vue"),
    },
    preSaleReport: {
        path: "/preSaleReport",
        name: "preSaleReport",
        meta: { title: "预售日销售报表", keepAlive: false },
        component: () => import("../views/presaleSalesReport/index.vue"),
    },
    presaleActivityReport: {
        path: "/presaleActivityReport",
        name: "presaleActivityReport",
        meta: { title: "预售活动销售报表", keepAlive: false },
        component: () => import("../views/presaleActivityReport/index.vue"),
    },
    presaleGoodsReport: {
        path: "/presaleGoodsReport",
        name: "presaleGoodsReport",
        meta: { title: "预售商品销售报表", keepAlive: false },
        component: () => import("../views/presaleGoodsReport/index.vue"),
    },
    interfaceAccessStatistics: {
        path: "/interfaceAccessStatistics",
        name: "interfaceAccessStatistics",
        meta: { title: "接口访问统计", keepAlive: false },
        component: () => import("../views/interfaceAccessStatistics/index.vue"),
    },
    valueCard: {
        path: "/valueCard",
        name: "valueCard",
        meta: { title: "储值卡明细", keepAlive: false },
        component: () => import("../views/valueCard/index.vue"),
    },
    heatMap: {
        path: "/heatMap",
        name: "heatMap",
        meta: { title: "热力图", keepAlive: false },
        component: () => import("../views/heatMap/index.vue"),
    },
    promotionManagement: {
        path: "/promotionManagement",
        name: "promotionManagement",
        meta: { title: "推广管理", keepAlive: false },
        component: () => import("../views/promotionManagement/index.vue"),
    },
    lotteryDraw: {
        path: "/lotteryDraw",
        name: "lotteryDraw",
        meta: { title: "抽奖活动", keepAlive: false },
        component: () => import("../views/lotteryDraw/index.vue"),
    },
    liveBroadcast: {
        path: "/liveBroadcast",
        name: "liveBroadcast",
        meta: { title: "直播", keepAlive: false },
        component: () => import("../views/liveBroadcast/index.vue"),
    },
    liveEdit: {
        path: "/liveEdit",
        name: "liveEdit",
        meta: { title: "直播管理", keepAlive: false },
        component: () => import("../views/liveBroadcast/LiveEdit.vue"),
    },
};

const createRouter = () =>
    new Router({
        routes: commonRoutes,
        // scrollBehavior,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}
// const scrollBehavior = (to, from, savedPosition) => {
//     console.log('savedPosition: ', savedPosition);
//     if (savedPosition) {
//         return savedPosition;
//     } else {
//         return { x: 0, y: 0 };
//     }
// };
Vue.use(Router);
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export default router;
