import Vue from "vue";
import Vuex from "vuex";
// import { storage } from './storage';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isShowLoading: false, // 全局 loading
        // 左侧菜单栏数据
        menuItems: [],
        enumData: {}, //全局枚举值
        userData: "",

        tagsArr: [],
        productDetailData: {
            plu_name: "",
            cate_code: null,
            plu_code: "",
            goods_weghit: "",
            unit: "",
            bar_code: "",
            sale_mode: "",
            plu_weight: "",
            qa_days: "",
            prod_area: "",
            storage_temp_code: "",
            price: "",
            goods_min_count: "",
            vip_price: "",
            buying_price: "",
            remarks: "",
            goods_weight: "",
            plu_type: "",
        }, //编辑商品详情信息
        packageType: "", //打包商品操作类型
    },
    mutations: {
        setMenus(state, items) {
            state.menuItems = JSON.parse(sessionStorage.getItem("menuItems"));
        },
        setUserData(state, items) {
            state.userData = { ...items };
        },
        setLoading(state, isShowLoading) {
            state.isShowLoading = isShowLoading;
        },
        setEnumData(state, enumData) {
            state.enumData = JSON.parse(localStorage.getItem("enumData"));
        },
        setProductDetailData(state, productDetailData) {
            state.productDetailData = productDetailData;
        },
        setPackageType(state, packageType) {
            state.packageType = packageType;
        },
        // tagsArry
    },
});

export default store;
